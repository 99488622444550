import React, { useState } from "react";

import './styles/EarnForm.css'

import {
    PDFDownloadLink,
    Document,
    Page,
    Text,
    View,
    Image,
} from "@react-pdf/renderer";

function EarnForm() {

    const [sexVal, setSexVal] = useState('Male')
    const [imageDataURL, setImageDataURL] = useState(null)

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        age: '',
        experience: 'University',
        experience_years: '',
        money: '',
        whatsapp: '',
        phone: '',
    })

    const handleInputChange = e => {
        const {name, value} = e.target
        setFormData(prev => ({...prev, [name]: value}))
    }

    const imageHandler = e => {
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = () => {
            setImageDataURL(reader.result)
        }

        reader.readAsDataURL(file)
    }

    return (
        <div className="earn_form">
            <h1 className="earn_title">يرجى تعبئة الإستمارة التالية حتى يتم تسجيل طلبك:</h1>
            <div className="form_div">
                <div className="name_div">
                    <div className="earn_fname">
                        <h2 className="name_text">الإسم الأول:</h2>
                        <input type="text" className="fname_input" name="first_name" value={formData.first_name} onChange={handleInputChange}/>
                    </div>
                    <div className="earn_lname">
                        <h2 className="name_text">الإسم الأخير:</h2>
                        <input type="text" className="lname_input" name="last_name" value={formData.last_name} onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="age_sex">
                    <div className="age">
                        <h2 className="age_text">العمر</h2>
                        <input type="date" className="age_input" name="age" value={formData.age} onChange={handleInputChange}/>
                    </div>
                    <div className="sex">
                        <h2 className="sex_text">الجنس</h2>
                        <div className="male">
                            <h3 className="male_text">ذكر</h3>
                            <input type="radio" className="msex_input" checked={sexVal === 'Male'} onChange={() => setSexVal('Male')} />
                        </div>
                        <div className="female">
                            <h3 className="female_text">أنثى</h3>
                            <input type="radio" className="fsex_input" checked={sexVal === 'Female'} onChange={() => setSexVal('Female')} />
                        </div>
                    </div>
                </div>
                <div className="expi">
                    <div className="expi_type">
                        <label for="xp">الخبرة في مجال هذه الخدمة:</label>

                        <select className="expi_type_select" name="experience" value={formData.experience} onChange={handleInputChange} id="xp">
                            <option value="University">شهادة جامعية</option>
                            <option value="Training">تدريب خاص</option>
                            <option value="Interest">مجهود شخصي و اهتمام</option>
                        </select>
                    </div>
                    <div className="expi_years">
                        <h2 className="years_number">عدد سنين الخبرة في هذا المجال:</h2>
                        <input type="number" className="years_number" name="experience_years" value={formData.experience_years} onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="money">
                    <div className="earn_money_div">
                        <h2 className="money_text">ما هو المبلغ المالي الذي ترغب</h2>
                        <h2 className="money_text">بالحصول عليه مقابل هذه الخدمة (بالليرة السورية)</h2>
                        <input type="number" className="money_input" name="money" value={formData.money} onChange={handleInputChange}/>
                    </div>
                    <div className="image_div">
                        <h2 className="image_text">
                            تحميل ملفات لتقييم الأعمال السابقة
                        </h2>
                        <input type="file" className="image_inp" onChange={imageHandler} />
                    </div>
                </div>
                <div className="numbers">
                    <div className="whats_num">
                        <h2 className="whats_text">
                            رقم واتساب للتواصل:
                        </h2>
                        <input type="text" className="whats_inp" name="whatsapp" value={formData.whatsapp} onChange={handleInputChange}/>
                    </div>
                    <div className="phone_num">
                        <h2 className="phone_text">
                            رقم اتصال للتواصل:
                        </h2>
                        <input type="text" className="phone_inp" name="phone" value={formData.phone} onChange={handleInputChange}/>
                    </div>
                </div>
            </div>
            <div className="download_div">
            <PDFDownloadLink
                document={
                    <Document>
                        <Page>
                            <View>
                                <Text>Data</Text>
                                <Text>First Name: {formData.first_name}</Text>
                                <Text>Last Name: {formData.last_name}</Text>
                                <Text>Age: {formData.age}</Text>
                                <Text>Gender: {sexVal}</Text>
                                <Text>Experience: {formData.experience}</Text>
                                <Text>Experience Years: {formData.experience_years}</Text>
                                <Text>Salary: {formData.money}</Text>
                                <Text>WhatsApp: {formData.whatsapp}</Text>
                                <Text>Phone: {formData.phone}</Text>
                                <Image src={imageDataURL}/>
                            </View>
                        </Page>
                    </Document>
                }
                fileName="data.pdf"
            >
                {
                    ({blob, url, loading, error}) => loading ? <button className="sub_btn_red">يرجى اختيار ملف</button> : <button className="sub_btn">تنزيل</button>
                }
            </PDFDownloadLink>

            <div className="send_div">
                <h3>ثم أرسل الملف الذي قمت بتنزيله إلى الرابط التالي:</h3>
                <a href="https://wa.me/004917683243821" style={{color: "blue", textDecoration: "none"}}>إضغط هنا</a>
                <h3>أو عبر البريد الإلكتروني: electraspace@web.de</h3>
            </div>
            </div>
        </div>
    )
}

export default EarnForm;