import React from 'react';
import './styles/About.css';

function About() {
  return (
    <div className="about">
      <h1 className="about_h">
        Electra Space
      </h1>
      <div className="about_p_div">
        <p className="about_p">
          A German / Syrian company offers jobs in the field of questionnaires, statistics, translation, transcription, photography, montage and many other fields.
        </p>
        <p className="about_p">
          It provides services in the field of web design, software, advertising and application design.
        </p>
        <p className="about_p">
          In addition to a distinguished work team that nurtures talents and delivers them to the world and success through social networking sites and professional and organized work.
        </p>
        <p className="about_p">
          This company enjoys high credibility with employees and customers .. and considers them part of the partner work team in every success .. Work with employees and customers is carried out through contracts documented by both parties, with an accurate financial system that guarantees profits for all.
        </p>
      </div>
    </div>
  )
}

export default About
