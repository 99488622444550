import React from 'react';
import './styles/Business.css';
import CardItem from '../../CardItem';

function Business() {
  return (
    <div className='business__cards' id='service__cards'>
      <h1>إدارة الأعمال</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/business.png'
              text='دراسة جدوى إقتصادية للمشاريع'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/business.png'
              text='التحليل الإحصائي'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/business.png'
              text='التنظيم الإداري و تأسيس فريق عمل'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/business.png'
              text='المحاسبة و الدراسة المالية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/business.png'
              text='استشارة إدارية و مالية للمشروع'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Business;
