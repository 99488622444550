import React from 'react';
import './styles/Instagram.css';
import CardItem from '../../CardItem';

function Instagram() {
  return (
    <div className='instagram__cards' id='service__cards'>
      <h1>انستاغرام</h1>
      <div className='tiktok__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/social.png'
              text='متابعات الصفحة الشخصية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='الإعجابات بالصور'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='التعليقات على الصور'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src='../../images/services/social.png'
              text='المشاهدات على الفيديو'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Instagram;
