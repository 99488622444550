import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import About from './components/pages/About';
import Marketing from './components/pages/services/Marketing';
import Content from './components/pages/services/Content';
import Social from './components/pages/services/Social';
import Translating from './components/pages/services/Traslating';
import University from './components/pages/services/University';
import Design from './components/pages/services/Design';
import Program from './components/pages/services/Program';
import Records from './components/pages/services/Records';
import Graphic from './components/pages/services/Graphic';
import Business from './components/pages/services/Business';
import ContactUs from './components/pages/ContactUs';
import Facebook from './components/pages/services/Facebook';
import YouTube from './components/pages/services/YouTube';
import Instagram from './components/pages/services/Instagram';
import TikTok from './components/pages/services/TikTok';
import ServiceContact from './components/pages/ServiceContact';
import Ideas from './components/pages/services/Ideas';
import EarnForm from './components/pages/EarnForm';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact children={<Home />} />
          <Route path='/services/social/facebook' children={ <Facebook />} />
          <Route path='/services/social/youtube' children={ <YouTube />} />
          <Route path='/services/social/instagram' children={ <Instagram />} />
          <Route path='/services/social/tiktok' children={ <TikTok />} />
          <Route path='/services/marketing' children={ <Marketing />} />
          <Route path='/services/content' children={ <Content />} />
          <Route path='/services/social' children={ <Social />} />
          <Route path='/services/ideas' children={ <Ideas />} />
          <Route path='/services/university' children={ <University />} />
          <Route path='/services/translating' children={ <Translating />} />
          <Route path='/services/design' children={ <Design />} />
          <Route path='/services/program' children={ <Program />} />
          <Route path='/services/records' children={ <Records />} />
          <Route path='/services/graphic' children={ <Graphic />} />
          <Route path='/services/business' children={ <Business />} />
          <Route path='/services/service-contact' children={ <ServiceContact />} />
          <Route path='/contact-us' children={<ContactUs />} />
          <Route path='/about-us' children={ <About />} />
          <Route path='/earn_form' children={ <EarnForm />} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
