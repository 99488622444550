import React from 'react';
import './styles/Social.css';
import CardItem from '../../CardItem';

function Social() {
  return (
    <div className='social__cards' id='service__cards'>
      <h1>دعم مواقع التواصل الإجتماعي</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/social.png'
              text='دعم فيسبوك'
              label=''
              path='/services/social/facebook'
            />
            <CardItem
              src='../../images/services/social.png'
              text='دعم يوتيوب'
              label=''
              path='/services/social/youtube'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='../../images/services/social.png'
              text='دعم انستاغرام'
              label=''
              path='/services/social/instagram'
            />
            <CardItem
              src='../../images/services/social.png'
              text='دعم تيك توك'
              label=''
              path='/services/social/tiktok'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Social;
