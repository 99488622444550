import React from 'react';
import './styles/ServiceCards.css';
import CardItem from './CardItem';

function ServiceCards() {
  return (
    <div className='service__cards' id='service__cards'>
      <h1>اكتشف أحدث خدماتنا</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/services/marketing.png'
              text='إذا كنت تريد التسويق الإعلامي الصحيح لمنتجاتك أو شركتك ، سنوفر لك أفضل الإعلانات الترويجية وأكثرها.'
              label='التسويق الإلكتروني'
              path='/services/marketing'
            />
            <CardItem
              src='images/services/social.png'
              text='هل لديك شغف واهتمام بمواقع التواصل الاجتماعي وتود أن تصبح من المشاهير ..!؟ حسنًا .. تقدم لك شركتنا الدعم للوصول إلى حلمك والحصول على أكبر عدد من الجمهور.'
              label='دعم مواقع التواصل الاجتماعي'
              path='/services/social'
            />
            <CardItem
              src='images/services/content.png'
              text='هل لديك موهبة وترغب في مشاركتها على وسائل التواصل الاجتماعي بطريقة احترافية؟ سيكون فريق شركتنا بجانبك وسيضمن لك الشهرة والنجاح.'
              label='دعم صانعي المحتوى'
              path='/services/content'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/services/translate.png'
              text='هل لديك مهارات متقدمة في اللغة الإنجليزية؟ حسنًا .. ستقدم لكم شركتنا عروضًا خاصة في مجال الترجمة للحصول على راتب شهري مضمون وكافٍ.'
              label='الترجمة'
              path='/services/translating'
            />
            <CardItem
              src='images/services/university.png'
              text='خدمات و مساعدة في مشاريعك الجامعية'
              label='خدمات جامعية'
              path='/services/university'
            />
            <CardItem
              src='images/services/decor.png'
              text='المساعدة في إنشاء التصاميم الهندسية و الديكور'
              label='التصميم الهندسي و الديكور'
              path='/services/design'
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src='images/services/program.png'
              text='برمجة و تصميم المواقع و التطبيقات'
              label='البرمجة و التصميم'
              path='/services/program'
            />
            <CardItem
              src='images/services/records.png'
              text='التسجيلات الصوتية و الدبلجة'
              label='دبلجة و تسجيلات'
              path='/services/records'
            />
            <CardItem
              src='images/services/graphic.png'
              text='المونتاج و الغرافيك'
              label='المونتاج و الغرافيك'
              path='/services/graphic'
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src='images/services/business.png'
              text='إدارة الأعمال'
              label='إدارة الأعمال'
              path='/services/business'
            />
            <CardItem
              src='images/services/ideas.png'
              text='دعم المواهب و الأفكار'
              label='دعم المواهب و الأفكار'
              path='/services/ideas'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServiceCards;
