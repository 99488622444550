import React from 'react';
import './styles/Design.css';
import CardItem from '../../CardItem';

function University() {
  return (
    <div className='design__cards' id='service__cards'>
      <h1>التصميم الهندسي و الديكور</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src='../../images/services/decor.png'
              text='تصميم شقق سكنية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/decor.png'
              text='تصميم فيلات و أبنية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/decor.png'
              text='تصميم أبنية تجارية و خدمية (مول - مستشفى - ...)'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/decor.png'
              text='تصميم ديكور داخلي للشقق السكنية و الشركات و المكاتب'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/decor.png'
              text='تصميم الواجهات و الإكساءات الخارجية'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default University;
