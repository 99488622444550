import React from 'react';
import './styles/Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/about-us'>Who are we ?</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact-us'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a href="https://facebook.com/profile.php?id=100091793221395" style={{color: 'white'}} target='_blank' rel="noopener noreferrer">Facebook</a>
            <a href="https://instagram.com/electraspace1" style={{color: 'white'}} target='_blank' rel="noopener noreferrer">Instagram</a>
            <a href="https://wa.me/004917683243821" style={{color: 'white'}} target='_blank' rel="noopener noreferrer">WhatsApp</a>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              Electra Space
              <i className='fab fa-typo3' />
            </Link>
          </div>
          <small className='website-rights'>Electra Space © 2023</small>
          <div className='social-icons'>
              <a href="https://facebook.com/profile.php?id=100091793221395" style={{color: 'white'}} className="social-icon-link facebook" target='_blank' rel="noopener noreferrer">
                <i className='fab fa-facebook-f' />
              </a>
              <a href="https://wa.me/004917683243821" style={{color: 'white'}} className="social-icon-link whatsapp" target='_blank' rel="noopener noreferrer">
                <i className='fab fa-whatsapp' />
              </a>
              <a href="https://instagram.com/electraspace1" style={{color: 'white'}} className="social-icon-link instagram" target='_blank' rel="noopener noreferrer">
                <i className='fab fa-instagram' />
              </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
