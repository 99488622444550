import React from 'react';
import './styles/Graphic.css';
import CardItem from '../../CardItem';

function Graphic() {
  return (
    <div className='graphic__cards' id='service__cards'>
      <h1>المونتاج و الغرافيك</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/graphic.png'
              text='تصوير و مونتاج فيديو'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/graphic.png'
              text='موشن فيديو'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/graphic.png'
              text='تصوير شعار'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/graphic.png'
              text='تعديل صور'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/graphic.png'
              text='تصميم صور إعلانية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/graphic.png'
              text='تصميم هوية بصرية'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className="cards__items">
          <CardItem
              src='../../images/services/graphic.png'
              text='تصميم صور الغلاف'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Graphic;
