import React from 'react';
import './styles/Marketing.css';
import CardItem from '../../CardItem';

function Marketing() {
  return (
    <div className='marketing__cards' id='service__cards'>
      <h1>التسويق الإلكتروني</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/marketing.png'
              text='حملة جوجل إعلانية و دعمها في محركات البحث'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/marketing.png'
              text='خطة تسويقية متكاملة لمشروعك التجاري'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/marketing.png'
              text='إعلانات ممولة على مواقع التواصل الإجتماعي'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='../../images/services/marketing.png'
              text='موشن فيديو لإعلانك التجاري'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/marketing.png'
              text='فيديو ترويجي متكامل (سيناريو - ممثلين - تصوير - مونتاج)'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/marketing.png'
              text='تصميم تطبيق سوق إلكتروني'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Marketing;
