import React from 'react';
import '../App.css';
import { Button } from './Button';
import './styles/HeroSection.css';
import Logo from '../assets/main_logo.png'

function HeroSection() {
  return (
    <div className='hero-container'>
      <img src={Logo} alt="space" width="460px" className="hero__logo"/>
      <p>Get your service.</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          page='/about-us'
        >
          Learn More
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
