import React from 'react';
import './styles/Program.css';
import CardItem from '../../CardItem';

function Program() {
  return (
    <div className='program__cards' id='service__cards'>
      <h1>البرمجة و التصميم</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/program.png'
              text='تصميم مواقع ويب'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/program.png'
              text='تصميم تطبيق لأجهوزة الأندرويد'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/program.png'
              text='تصميم تطبيق لأجهزة الـ iOS'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Program;
