import React from 'react';
import './styles/ServiceContact.css';
import { Link } from 'react-router-dom';

function ServiceContact() {
  return (
    <div className="ServiceContact">
    <h1>ما الطريقة التي تفضلها للتواصل و تقديم العرض الأفضل لخدمتك ؟</h1>
     <div className="contacts_div">
        <div className="facebook_text">
          <i className='fab fa-facebook' />
          <p className="content_text">
            تواصل معنا عبر صفحتنا على فيسبوك:
          </p>
          <a href="https://facebook.com/profile.php?id=100091793221395" className="hrefs" style={{textDecoration: "none", color: "blue"}} target='_blank' rel="noopener noreferrer">Electra Space</a> 
        </div>
        <div className="whatsapp_text">
          <i className='fab fa-whatsapp' />
          <p className="content_text">
          عبر تطبيق واتساب:
          </p>
          <a href="https://wa.me/004917683243821" className="hrefs" style={{textDecoration: "none", color: "blue"}} target='_blank' rel="noopener noreferrer">WhatsApp</a>
        </div>
        <div className="instagram_text">
          <i className='fab fa-instagram' />
          <p className="content_text">
          عبر تطبيق انستاغرام:
          </p>
          <a href="https://instagram.com/electraspace1" className="hrefs" style={{textDecoration: "none", color: "blue"}} target='_blank' rel="noopener noreferrer">Electra Space</a> 
        </div>
        <div className="email_text">
          <i className='fa fa-envelope' />
          <p className="content_text">
          أو عبر البريد الإلكتروني:
          </p>
          <p>electraspace@web.de</p>
        </div>
      </div>
      <h2 className="want_text">هل ترغب في تقديم هذه الخدمة و كسب المال ؟</h2>
      <Link
        to="/earn_form"
        style={{textDecoration: "none"}}
      >إضغط هنا</Link>
    </div>
  )
}

export default ServiceContact;