import React from 'react';
import './styles/Translating.css';
import CardItem from '../../CardItem';

function Translating() {
  return (
    <div className='translating__cards' id='service__cards'>
      <h1>الترجمة</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/translate.png'
              text='ترجمة النصوص من اللغة العربية إلى اللغة الإنجليزية و بالعكس.'
              label='عربي - إنجليزي'
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/translate.png'
              text='ترجمة النصوص من اللغة العربية إلى اللغة الألمانية و بالعكس.'
              label='عربي - ألماني'
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/translate.png'
              text='ترجمة النصوص من اللغة العربية إلى اللغة الروسية و بالعكس.'
              label='عربي - روسي'
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/translate.png'
              text='التدقيق اللغوي'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/translate.png'
              text='ترجمة و تنقيح البحث العلمي و الأطروحات الجامعية من اللغة العربية إلى اللغة الإنجليزية و بالعكس.'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/translate.png'
              text='كتابة الترجمة على الفيديو'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Translating;
