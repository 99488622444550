import React from 'react';
import './styles/Ideas.css';

function Ideas() {
  return (
    <div className="Ideas">
      <h1>دعم المواهب و الأفكار</h1>
      <div className="content_wrap">
        <p className="content_text">
            إذا كنت تملك الموهبة في أحد المجالات أو لديك أفكار رائدة .. تقدم لك شركتنا الدعم الكامل لصقل هذه الموهبة و تقديمها بالشكل الأفضل و التخطيط لإنجاز الأفكار مع الدعم المادي و الفني و اللوجستي .. فقط أرسل لنا المعلومات و البيانات و الملفات الخاصة بالفكرة و سيقوم فريق العمل بمراجعتها و الإتصال بك في أقرب وقت.
        </p>
        <div className="contacts_div">
          <div className="facebook_text">
            <i className='fab fa-facebook' />
            <p className="content_text">
            أرسل بياناتك و الملفات المرفقة على صفحتنا على فيسبوك:
            </p>
            <a href="https://facebook.com/profile.php?id=100091793221395" className="hrefs" style={{textDecoration: "none", color: "blue"}} target='_blank' rel="noopener noreferrer">Electra Space</a> 
          </div>
          <div className="whatsapp_text">
            <i className='fab fa-whatsapp' />
            <p className="content_text">
            عبر تطبيق واتساب:
            </p>
            <a href="https://wa.me/004917683243821" style={{textDecoration: "none", color: "blue"}} target='_blank' rel="noopener noreferrer">WhatsApp</a>
          </div>
          <div className="instagram_text">
            <i className='fab fa-instagram' />
            <p className="content_text">
            عبر تطبيق انستاغرام:
            </p>
            <a href="https://instagram.com/electraspace1" style={{textDecoration: "none", color: "blue"}} className="hrefs" target='_blank' rel="noopener noreferrer">Electra Space</a> 
          </div>
          <div className="email_text">
            <i className='fa fa-envelope' />
            <p className="content_text">
            أو عبر البريد الإلكتروني:
            </p>
            <p>electraspace@web.de</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ideas;
