import React from 'react';
import './styles/YouTube.css';
import CardItem from '../../CardItem';

function YouTube() {
  return (
    <div className='youtube__cards' id='service__cards'>
      <h1>يوتيوب</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItem
                    src='../../images/services/social.png'
                    text='الإشتراكات بالقناة'
                    label=''
                    path='/services/service-contact'
                />
                <CardItem
                    src='../../images/services/social.png'
                    text='المشاهدات على الفيديو'
                    label=''
                    path='/services/service-contact'
                />
                <CardItem
                    src='../../images/services/social.png'
                    text='المشاهدات على الفيديو القصير'
                    label=''
                    path='/services/service-contact'
                />
            </ul>
            <ul className="cards__items">
                <CardItem
                    src='../../images/services/social.png'
                    text='الإعجابات بالفيديو'
                    label=''
                    path='/services/service-contact'
                />
                <CardItem
                    src='../../images/services/social.png'
                    text='لايف ستريم'
                    label=''
                    path='/services/service-contact'
                />
                <CardItem
                    src='../../images/services/social.png'
                    text='التعليقات على الفيديو'
                    label=''
                    path='/services/service-contact'
                />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default YouTube;
