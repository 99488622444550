import React from 'react';
import './styles/Facebook.css';
import CardItem from '../../CardItem';

function Facebook() {
  return (
    <div className='facebook__cards' id='service__cards'>
      <h1>فيسبوك</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/social.png'
              text='المتابعين للصفحة الشخصية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='المعجبين بالصفحات العامة'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='أعضاء المجموعات'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/social.png'
              text='الإعجابات على المنشورات'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='التعليقات على المنشورات'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='المشاهدات على الفيديو'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src='../../images/services/social.png'
              text='دعم البث المباشر'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='فيسبوك لايف ستريم'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Facebook;
