import React from 'react';
import './styles/TikTok.css';
import CardItem from '../../CardItem';

function TikTok() {
  return (
    <div className='facebook__cards' id='service__cards'>
      <h1>تيك توك</h1>
      <div className='tiktok__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/social.png'
              text='مشاهدات الفيديو'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='الإعجاب بالفيديو'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='متابعة الصفحة'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src='../../images/services/social.png'
              text='التعليق على الفيديو'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/social.png'
              text='لايف ستريم'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TikTok;
