import React from 'react';
import './styles/Records.css';
import CardItem from '../../CardItem';

function Records() {
  return (
    <div className='records__cards' id='service__cards'>
      <h1>التسجيلات الصوتية و الدبلجة</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/records.png'
              text='تعليق صوتي على الفيديو'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/records.png'
              text='الهندسة الصوتية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/records.png'
              text='لتوزيع الموسيقي للحن'
              label=''
              path='/services/service-contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../../images/services/records.png'
              text='تصميم و توزيع الشارات الغنائية للمسلسلات'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/records.png'
              text='الموسيقى التصويرية'
              label=''
              path='/services/service-contact'
            />
            <CardItem
              src='../../images/services/records.png'
              text='الدبلجة الصوتية'
              label=''
              path='/services/service-contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Records;
